exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-busca-tsx": () => import("./../../../src/pages/busca.tsx" /* webpackChunkName: "component---src-pages-busca-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-empresa-tsx": () => import("./../../../src/pages/empresa.tsx" /* webpackChunkName: "component---src-pages-empresa-tsx" */),
  "component---src-pages-index-2-tsx": () => import("./../../../src/pages/index2.tsx" /* webpackChunkName: "component---src-pages-index-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-produtos-tsx": () => import("./../../../src/pages/produtos.tsx" /* webpackChunkName: "component---src-pages-produtos-tsx" */)
}

